<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'users'"
              :total="dataTotal"
              :fetch-path="'userPermissionPayrolls/fetch'"
              :show-actions-column="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'All',
          checked: true,
          order: 2,
        },
        {
          name: 'Researchers',
          checked: true,
          order: 3,
        },
      ],
      sortMapping: {
        Name: 'user.name',
        All: 'all',
        Researchers: 'researchers',
      },
      title: 'Users permissions for payrolls section',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'userPermissionPayrolls/items',
      dataTotal: 'userPermissionPayrolls/itemsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'user-permission-payrolls')
    await this.$store.dispatch('userPermissionPayrolls/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'user-permission-payrolls',
      })
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
